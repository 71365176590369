import { rol } from '../../utils'
import { useHistory } from 'react-router'

export const useRedirectToPathByRole = () => {
  const history = useHistory()
  const redirect = ({ data }) => {
    if (!data) { return }
    if (data.user.id_rol === rol.staff) {
      history.push('/dashboard/patients')
      return
    }
    if (data.user.id_rol === rol.center && data.user.id_medical_center) {
      history.push(`/dashboard/medical-center/${data.user.id_medical_center}`)
      return
    }
    history.push('/dashboard')
    return
  }

  return { redirect }
}
