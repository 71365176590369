import { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import contextKey from '../../utils/contextkeys'
import { AuthContext, loginUser, GeneratePassword } from '../../context/auth'
import LoginValidator from '../../validators/loginValidator'
import FormLogin from '../../components/formLogin'
import { AuthLayout } from '../../components/authLayout'
import { useRedirectToPathByRole } from '../../hooks/redirectByRole'

const Login = () => {
  const history = useHistory()
  const { redirect } = useRedirectToPathByRole()
  const [loading, setLoading] = useState(false)
  const { dispatch, state, setUser } = useContext(AuthContext)
  const [message, setMessage] = useState({})
  const [errMsj, setErrMsj] = useState(false)
  const [textError, setTextError] = useState('')
  const [openAlert, setOpenAlert] = useState({})
  const [showError, setShowError] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [errors, setErrors] = useState({
    error04: false,
    error05: false,
  })
  const [data, setData] = useState({
    email: '',
    password: '',
  })
  const handleChange = (e) => {
    setErrMsj(false)
    setData({
      ...data,
      [e.target.id]: e.target.value,
    })
  }
  useEffect(() => {
    if (state.token) {
      history.push('/dashboard')
    } else {
      if (showError) {
        const leyend = LoginValidator(data)
        if (!leyend.email && !leyend.password && !leyend.isemail) {
          setDisabled(false)
        } else {
          setDisabled(true)
        }
        setMessage(leyend)
      } else {
        setShowError(true)
      }
    }
  }, [data])
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await loginUser(dispatch, data, setUser)
    if (res?.data?.token) {
      redirect({ data: res.data })
      return
    }
    if (res.error?.code_error_login === '02') {
      data.firstTime = false
      dispatch({ type: contextKey.getUser, payload: data })
      history.push('/twofactor')
    }
    if (res.error?.code_error_login === '04') {
      setErrors({
        ...errors,
        error04: true,
      })
    }
    if (res.error?.code_error_login === '05') {
      setErrors({
        ...errors,
        error05: true,
      })
      setOpenAlert({
        disabled: true,
        alert: false,
      })

      const resp = await GeneratePassword(data.email)

      if (resp.data) {
        setOpenAlert({
          disabled: false,
          alert: true,
        })
      }
    }
    if (res.error?.code_error_login === '06') {
      setErrMsj(true)
      setTextError('auth.invalidcenter')
    }
    if (res.error?.code_error_login === '08') {
      data.firstTime = false
      dispatch({ type: contextKey.getUser, payload: data })
      history.push('/login-method')
    }
    if (res?.data?.authenticator) {
      data.firstTime = true
      dispatch({ type: contextKey.getUser, payload: data })
      history.push('/twofactor')
    } else {
      setErrMsj(true)
      setTextError('auth.invalidData')
    }
    setLoading(false)
  }
  const resetLogin = () => {
    setErrors({
      error04: false,
      error05: false,
    })
    setErrMsj(false)
  }
  const sendEmail = async () => {
    setOpenAlert({
      disabled: true,
      alert: false,
    })

    const resp = await GeneratePassword(data.email)

    if (resp.data) {
      setOpenAlert({
        disabled: false,
        alert: true,
      })
    }
  }

  return (
    <AuthLayout title="S'identifier">
      <FormLogin
        loading={loading}
        change={handleChange}
        submit={handleSubmit}
        message={message}
        errMsj={errMsj}
        errors={errors}
        resetLogin={resetLogin}
        sendEmail={sendEmail}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        disabled={disabled}
        textError={textError}
      />
    </AuthLayout>
  )
}

export default Login
